import React from "react";
import Support from "./support/Support";
import { Box, Typography, Modal, Grid, Paper, Button } from "@material-ui/core";

export const DashboardJsx = (props) => {
  return (
    <div className="main-container dashboard">
      <Box mt={3.157} mb={1} mx={3.157}>
        <Typography variant="h4" className="dashboard-title">
          Home
        </Typography>
      </Box>
      <Box mx={3.157}>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <Paper elevation={4} className="dashboard-card">
              <div className="dashboard-card-icon-container">
                <div className="dashboard-card-icon reports-icon-white"></div>
              </div>
              <Box m={1}>
                <Typography
                  align="center"
                  variant="h6"
                  className="upper-dashboard-title"
                >
                  Reports
                </Typography>
                <Typography
                  align="center"
                  variant="subtitle2"
                  className="upper-dashboard-title"
                >
                  {props.totalReports} Reports
                </Typography>
              </Box>
              <Box display="flex" mt={1.777} justifyContent="center">
                <Button
                  variant="contained"
                  className="btn-theme"
                  href="/dashboard/reports"
                >
                  View all reports
                </Button>
              </Box>
            </Paper>
          </Grid>

          <Grid item xs={6}>
            <Paper elevation={4} className="dashboard-card">
              <div className="dashboard-card-icon-container">
                <div className="dashboard-card-icon profile-icon-white"></div>
              </div>
              <Box m={1}>
                <Typography
                  align="center"
                  variant="h6"
                  className="upper-dashboard-title"
                >
                  My Profile
                </Typography>
                <Typography
                  align="center"
                  variant="subtitle2"
                  className="upper-dashboard-title"
                >
                  Account Profile
                </Typography>
              </Box>
              <Box mt={1.777} display="flex" justifyContent="center">
                <Button
                  variant="contained"
                  className="btn-theme"
                  href="/dashboard/profile"
                >
                  Edit my profile
                </Button>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Box>
      <div className="network-graphic"></div>
      <Modal open={props.open} onClose={() => props.handleClose()}>
        <Support />
      </Modal>
    </div>
  );
};

export default DashboardJsx;
