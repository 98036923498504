import React from "react";
import { Link } from "react-router-dom";
import {
  Modal,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import SidebarStyles from "./Sidebar.material";

export const SidebarJsx = (props) => {
  const classes = SidebarStyles();
  return (
    <div className="sidebar">
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={true}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <div class="logo-round"></div>
        </div>
        <List>
          <Link className="black-link" to="/dashboard">
            <ListItem button>
              <ListItemIcon className="sidebar-icon">
                <div class="icon dashboard-icon"></div>
              </ListItemIcon>
              <ListItemText primary="Home" />
            </ListItem>
          </Link>

          <Link className="black-link" to="/dashboard/reports">
            <ListItem button>
              <ListItemIcon className="sidebar-icon">
                <div class="icon reports-icon"></div>
              </ListItemIcon>
              <ListItemText primary="Reports" />
            </ListItem>
          </Link>
          {props.isQlikSheetExist && props.isQlikSheetExist === true && (
          <Link className="black-link" to="/dashboard/qlik">
            <ListItem button>
              <ListItemIcon className="sidebar-icon">
                <div class="icon reports-icon"></div>
              </ListItemIcon>
              <ListItemText primary="Dashboard" />
            </ListItem>
          </Link>
          )}

          {props.role && props.role === "vendor_admin" && (
            <Link className="black-link" to="/dashboard/user-management">
              <ListItem button>
                <ListItemIcon className="sidebar-icon">
                  <div class="icon user-management-icon"></div>
                </ListItemIcon>
                <ListItemText primary="User Management" />
              </ListItem>
            </Link>
          )}
        </List>
      </Drawer>
    </div>
  );
};

export default SidebarJsx;
